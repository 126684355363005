export type BreakpointSize = 320 | 375 | 480 | 600 | 768 | 1024 | 1440

// Breakpoints that follow our design specs
export enum Breakpoints {
  xs = 320,
  xs375 = 375,
  sm = 480,
  sm600 = 600,
  md = 768,
  lg = 1024,
  xl = 1440,
}
